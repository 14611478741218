import Component, { mixins } from 'vue-class-component';
import Global from 'src/mixins/Global';
import Metrics from 'src/mixins/Metrics';
import Verification from 'src/mixins/Verification';
import PhoneCallbacks from 'src/mixins/PhoneCallbacks';
import InputText from 'src/components/ui/input/InputText.vue';
import ButtonDefault from 'src/components/ui/buttons/ButtonDefault.vue';
import ErrorSequoia from 'src/components/ErrorSequoia.vue';
import LoaderSpinner from 'src/components/ui/loader/LoaderSpinner.vue';
import IconSVG from 'src/components/IconSVG.vue';
import InputCheckbox from 'src/components/ui/input/InputCheckbox.vue';
import BadgeSequoia from 'src/components/ui/BadgeSequoia.vue';
import { AUTH_STEPS, HTTP_CODES, LOCAL_STORAGE_NAMES } from 'src/constants';
import { cleanupPhone, formatPhone } from 'src/utils/phone';
import { wlDetector } from 'src/utils';
import { actions } from 'src/store/actions';
import logger from 'src/utils/logger';
import { Ref } from 'vue-property-decorator';
import * as api from 'src/api';
import { selectors } from 'src/store/selectors';
import { cookieStore, localStore, sessionStore } from 'src/utils/storage';
import { getAppVersionParams } from 'src/utils/app-version';
import { GaEventParams } from 'src/utils/metrics';
const log = logger('phone-unlink');
import IconGift from 'src/svg/gift.svg';
import { isOkObject } from 'src/utils/object';

const GA_STEPS = [AUTH_STEPS.error, AUTH_STEPS.outdated];
const OTHER_STEPS = [
  AUTH_STEPS.phoneFoundAfterReset,
  AUTH_STEPS.phoneFoundBeforeReset,
  AUTH_STEPS.finish,
  AUTH_STEPS.retryTrialStatus,
  AUTH_STEPS.phoneNotFound,
  AUTH_STEPS.emailFinish,
  AUTH_STEPS.unlinkLogin,
  AUTH_STEPS.unlinkOneOfMultipleLogins,
  AUTH_STEPS.fail,
  AUTH_STEPS.success,
  AUTH_STEPS.error,
  AUTH_STEPS.outdated,
];

@Component({
  components: {
    InputText,
    ButtonDefault,
    ErrorSequoia,
    LoaderSpinner,
    IconSVG,
    InputCheckbox,
    BadgeSequoia,
  },
})
export default class Auth extends mixins(Global, Metrics, Verification, PhoneCallbacks) {
  IconGift = IconGift;
  steps = AUTH_STEPS;
  initialLoading = true;
  nextStepAfterGetLogins = '';
  email = '';
  errors: Array<{ code: ''; message: string; name: string }> = [];
  simAuthSuccess = false;
  authWidthPasswordAndPromoCodes = false;
  megafonJwtCheckCookieName = 'megafon_jwt_checked';

  itemsLoginType = [
    {
      index: 0,
      number: 1,
      text: 'by_sms_code',
    },
    {
      index: 1,
      number: 2,
      text: 'by_login',
    },
  ];

  @Ref('recaptcha')
  refRecaptcha: any;

  get isSmotreshka() {
    return wlDetector().isSmotreshka;
  }
  get isAltel() {
    return wlDetector().isAltel;
  }
  get isUztel() {
    return wlDetector().isUztel;
  }
  get isKt() {
    return wlDetector().isKt;
  }
  get isMts() {
    return wlDetector().isMts;
  }
  get isTele2() {
    return wlDetector().isTele2;
  }
  get isN3() {
    return wlDetector().isN3;
  }
  get isMegacom() {
    return wlDetector().isMegacom;
  }
  get isMegafon() {
    return wlDetector().isMegafon;
  }
  get isIntersv() {
    return wlDetector().isIntersv;
  }

  get phoneCleaned() {
    return cleanupPhone(this.phone, false);
  }

  get errorCaptcha() {
    return this.errors.find((e) => e.name === 'Captcha');
  }

  get errorLogin() {
    return this.errors.find((e) => e.name === 'login');
  }

  get hasOtherErrors() {
    return this.errors.length && !isOkObject(this.errorCaptcha) && !isOkObject(this.errorLogin);
  }

  get messageForPhoneInput() {
    return this.incompletePhone
      ? this.getTranslation('incomplete_phone_reg')
      : this.errorLogin
      ? this.errorLogin.message
      : '';
  }

  get statusForPhoneInput() {
    return this.incompletePhone || this.errorLogin ? 'error' : undefined;
  }

  get messageForLoginInput() {
    return this.errorLogin ? this.errorLogin.message : '';
  }

  get statusForLoginInput() {
    return this.errorLogin ? 'error' : undefined;
  }

  get messageForCaptchaInput() {
    return this.errorCaptcha ? this.errorCaptcha.message : '';
  }

  get statusForCaptchaInput() {
    return this.errorCaptcha ? 'error' : undefined;
  }

  get currentStep() {
    return this.$store.authAndReg.currentStep;
  }

  set currentStep(step) {
    this.$store.authAndReg.currentStep = step;
  }

  get currentStepComponent() {
    if (OTHER_STEPS.includes(<string>this.$store.authAndReg.currentStep)) {
      return 'other';
    }

    return this.$store.authAndReg.currentStep;
  }

  get isLoading() {
    return this.$store.authAndReg.isLoading;
  }

  set isLoading(value) {
    this.$store.authAndReg.isLoading = value;
  }

  get phone() {
    return this.$store.authAndReg.phone;
  }

  set phone(phone) {
    this.$store.authAndReg.phone = phone;
  }

  get captchaValue() {
    return this.$store.authAndReg.captchaValue;
  }

  set captchaValue(value) {
    this.$store.authAndReg.captchaValue = value;
  }

  get useRecaptcha() {
    return !!(this.$store.authAndReg.useRecaptcha && this.$store.authAndReg.recaptchaId);
  }

  set useRecaptcha(use: boolean) {
    this.$store.authAndReg.useRecaptcha = use;
  }

  get checkCode() {
    return this.$store.authAndReg.checkCode;
  }

  set checkCode(code) {
    this.$store.authAndReg.checkCode = code;
  }

  get isPhoneMode() {
    return this.$store.isPhoneMode;
  }

  get isAuth() {
    return this.$route?.name === 'login' && this.$route?.query?.type !== 'reg';
  }

  get incompletePhone() {
    return this.$store.incompletePhone;
  }

  get incompletePhoneInitialFlag() {
    return this.$store.incompletePhoneInitialFlag;
  }

  get errorText() {
    return this.$store.authAndReg.errorText;
  }

  set errorText(text) {
    this.$store.authAndReg.errorText = text;
  }

  get textBelowRegButton() {
    return this.getTranslation(
      this.$store.authAndReg.textBelowRegButtonId ? this.$store.authAndReg.textBelowRegButtonId : ''
    );
  }
  get errorMessages() {
    return this.$store.authAndReg.errorMessages;
  }

  set errorMessages(messages) {
    this.$store.authAndReg.errorMessages = messages;
  }

  get currentLogin() {
    return this.$store.authAndReg.currentLogin;
  }

  set currentLogin(login) {
    this.$store.authAndReg.currentLogin = login;
  }

  get passwordMinLength() {
    return selectors.common.authAndRegPasswordMinSelector(this.$store);
  }

  get passwordMaxLength() {
    return selectors.common.authAndRegPasswordMaxSelector(this.$store);
  }

  get phoneUnsupported() {
    return this.$store.authAndReg.phoneUnsupported;
  }

  get usePlusInPhoneMethods() {
    return this.$store.authAndReg.usePlusInPhoneMethods;
  }

  get currentLoginType() {
    return this.$store.isPhoneMode ? 0 : 1;
  }

  mounted() {
    this.errors.length = 0;
    this.errorMessages.length = 0;

    this.itemsLoginType = this.itemsLoginType.map((type) => ({
      ...type,
      text: this.getTranslation(type.text),
    }));
  }

  beforeDestroy() {
    this.errors.length = 0;
    this.errorMessages.length = 0;
  }

  changeStep(step: string): void {
    if (!AUTH_STEPS.hasOwnProperty(step)) {
      this.currentStep = AUTH_STEPS.start;
      return;
    }

    this.currentStep = step;
    this.isLoading = false;

    if (GA_STEPS.includes(step)) {
      this.gaEventAuth({
        action: step,
        error_text: AUTH_STEPS.error ? 'Что-то пошло не так' : 'Сессия истекла',
      });
    }

    if (step === AUTH_STEPS.error) {
      this.$_verification_clearTimeout(this.timeoutIdUntilSessionTokenOutdated);
    }
  }

  formatPhone(phone: string) {
    return formatPhone(phone);
  }

  showErrorPage(err: any) {
    this.errorText = err?.message || err?.msg || err?.toString() || 'Unknown error';
    this.changeStep(AUTH_STEPS.error);
  }

  async registerSimPassword() {
    const data: {
      phone: string;
      password: string;
      promocode?: string;
    } = {
      phone: cleanupPhone(this.phone, false),
      password: this.$store.authAndReg.passwordAuth,
    };

    if (this.$store.authAndReg.promoCodeValid && this.$store.authAndReg.logins.length <= 1) {
      data.promocode = this.$store.authAndReg.promoCodeId;
    }

    try {
      await api.auth.registerSimPassword({
        data,
      });

      this.simAuthSuccess = true;
      const nextStep = this.$store.authAndReg.promoCodeValid
        ? AUTH_STEPS.promoCodeSuccess
        : AUTH_STEPS.finish;
      this.changeStep(nextStep);
    } catch (err) {
      this.handleError(err);
      this.$store.authAndReg.passwordAuth = '';
    }
  }

  flushProcess(clearPhone = true) {
    if (clearPhone) {
      this.phone = '';
    }
    this.captchaValue = '';
    this.errorText = '';
    this.changeStep(AUTH_STEPS.start);
    this.errors.length = 0;
    this.errorMessages.length = 0;
    this.$_verification_clearTimeout(this.timeoutIdUntilSessionTokenOutdated);
    this.$_verification_stopTimer();
  }

  async sendNewPassword() {
    if (this.isLoading) {
      return;
    }

    this.isLoading = true;

    try {
      await actions.authAndReg.sendPassword(
        this.$store,
        this.currentLogin || this.$store.authAndReg.logins[0]['login']
      );
      this.changeStep(AUTH_STEPS.phoneFoundAfterReset);
      this.gaEventAuth({ action: 'Выслать новый пароль' });
    } catch (err) {
      this.showErrorPage(err);
    }
  }

  handleError(err: any) {
    this.errors.length = 0;
    this.errorMessages.length = 0;

    this.isLoading = false;

    if (Array.isArray(err.errors) && err.errors.length) {
      err.message = err.errors?.[0]?.msg || this.getTranslation('Error');
      err.code = HTTP_CODES.BAD_REQUEST;
      err.name = err.errors?.[0]?.name || '';
    }

    if (err.code === HTTP_CODES.BAD_REQUEST || err.name === 'Error') {
      this.captchaValue = '';
      this.$store.authAndReg.captchaRefreshValue++;
      this.errors.push(err);

      if (this.hasOtherErrors) {
        this.errorMessages.push(err.message);
      }
    } else {
      this.showErrorPage(err);
    }
  }

  onInput(event: any) {
    if (isNaN(parseFloat(event.key))) {
      event.preventDefault();
    }
  }

  gaEventAuth(params: GaEventParams) {
    if (this.$store.authAndReg.gaNameComponent) {
      params.category = this.$store.authAndReg.gaNameComponent;
      this.gaEvent(params);
    }
  }

  gaEventOnceAuth(params: GaEventParams) {
    if (this.$store.authAndReg.gaNameComponent) {
      params.category = this.$store.authAndReg.gaNameComponent;
      this.gaEventOnce(params);
    }
  }

  changePhoneClick() {
    this.flushProcess();
    this.gaEventAuth({ action: 'Клик по кнопке "Ввести другой номер"' });
  }

  async checkUseRecaptcha() {
    this.useRecaptcha = !!this.$store.authAndReg?.recaptchaId;
  }

  async start() {
    if (this.isLoading) {
      return;
    }

    this.isLoading = true;
    this.errors.length = 0;
    this.errorMessages.length = 0;

    if (this.$store.authAndReg.phoneTechnicalSim) {
      this.changeStep(this.steps.simPassword);
      return;
    }

    try {
      this.gaEventAuth({ action: 'Клик по кнопке "Далее" после ввода телефона' });
      this.yaMetrikaGoal('Phone_submit');

      if (this.useRecaptcha) {
        this.refRecaptcha.execute();
      } else {
        await this.contactVerification(null);
      }
    } catch (err) {
      this.handleError(err);

      this.gaEventAuth({
        action: 'Клик по кнопке "Далее" после ввода телефона',
        error_text: err.message,
      });
    }
  }

  sendRestorePasswordEmail = api.auth.restorePassword;

  async onRecaptchaExecute(captchaToken: any) {
    this.isLoading = true;

    try {
      await this.contactVerification(captchaToken);
    } catch (err) {
      if (err.status === HTTP_CODES.BAD_REQUEST) {
        this.useRecaptcha = false;
        this.changeStep(AUTH_STEPS.captcha);
      } else {
        this.showErrorPage(err);
      }
    }
  }

  async contactVerification(captchaToken?: any) {
    this.isLoading = true;

    if (!this.isPhoneMode && this.email) {
      this.gaEventAuth({ action: 'Клик по кнопке "Далее" после ввода логина' });

      if (this.isAuth) {
        await actions.authAndReg.login(
          this.$store,
          this.email,
          this.$store.authAndReg.passwordAuth
        );

        const QRCode = this.$route.query.qr;

        if (QRCode) {
          await actions.authAndReg.QRLogin(this.$store, QRCode);
          await this.$router.replace('/qr-success');
        }
        await this.redirectAfterLogin();
      } else {
        await this.sendRestorePasswordEmail({
          data: {
            login: this.email.toLowerCase(),
            captcha: this.captchaValue,
            cookies: this.$store.authAndReg.cookieCaptcha,
          },
        });
        this.changeStep(AUTH_STEPS.emailFinish);
      }

      return true;
    }

    try {
      await actions.registration.verifyContacts(
        this.$store,
        this.phone,
        captchaToken ? captchaToken : this.captchaValue,
        this.$store.authAndReg.verificationComponentName,
        {},
        this.useRecaptcha,
        this.$store.authAndReg.cookieCaptcha
      );

      this.changeStep(AUTH_STEPS.checkCode);
    } catch (err) {
      if (err.status === HTTP_CODES.BAD_REQUEST) {
        this.isLoading = false;
        this.gaEventAuth({ action: 'Неверная капча' });
      }
      throw err;
    }
  }

  async retry() {
    this.gaEventAuth({ action: 'Клик по кнопке "Выслать заново"' });
    await actions.registration.retry(this.$store, {
      data: {
        type: 'phone',
        id: this.$store.contactsVerification.id,
      },
    });

    this.$_verification_startTimer();
  }

  async verifyAndGetLogins(checkCode: string, login = '') {
    this.errors.length = 0;
    this.errorMessages.length = 0;

    try {
      this.$_verification_clearTimeout(this.timeoutIdUntilSessionTokenOutdated);

      await actions.registration.verify(this.$store, {
        data: {
          type: 'phone',
          contacts: login || cleanupPhone(this.phone, this.usePlusInPhoneMethods.registration),
          code: checkCode,
        },
      });

      this.gaEventAuth({ action: 'Сабмит кода из смс' });

      this.$_verification_setTimeout(this.changeStep, AUTH_STEPS.outdated);

      await actions.authAndReg.getAvailableLogins(this.$store);

      this.$store.authAndReg.logins = this.$store.availableLogins?.options || [];

      switch (this.$store.authAndReg.logins.length) {
        case 0:
          this.changeStep(AUTH_STEPS.phoneNotFound);
          break;
        case 1:
          this.currentLogin = this.$store.authAndReg.logins[0].login;
          this.changeStep(this.nextStepAfterGetLogins);
          break;
        default:
          this.changeStep(AUTH_STEPS.multipleLogins);
          break;
      }
    } catch (err) {
      this.gaEventAuth({ action: 'Сабмит кода из смс', error_text: err.message });
      log.error(err);
      if (err.code === HTTP_CODES.NOT_FOUND) {
        this.changeStep(AUTH_STEPS.outdated);
      } else {
        this.errorMessages.push(err.message);
        this.isLoading = false;
        throw err;
      }
    }
  }

  async promoCodeRegister() {
    try {
      const { message } = await api.auth.registerWithPromoCodeMobile({
        params: { 'contacts-session-token': this.$store.verificationResult.sessionToken },
        data: { promocode: this.$store.authAndReg.promoCodeId },
      });
      this.$store.authAndReg.promoCodeResultText = message;
      this.changeStep(this.steps.promoCodeSuccess);
    } catch (err) {
      const nextStep =
        err?.code === HTTP_CODES.BAD_REQUEST
          ? this.steps.promoCodeUserExist
          : this.steps.promoCodeNotFound;
      this.changeStep(nextStep);
    }
  }

  async auth() {
    if (this.isLoading) {
      return;
    }

    this.isLoading = true;
    this.errors.length = 0;
    this.errorMessages.length = 0;

    const login = this.isPhoneMode
      ? cleanupPhone(this.phone, this.usePlusInPhoneMethods.auth)
      : this.phone;

    if (this.$store.authAndReg.promoCodeValid) {
      await this.authWidthPromoCode(login);
      return;
    }

    this.isLoading = true;
    this.errorMessages = [];

    const QRCode = this.$route.query.qr;

    try {
      await actions.authAndReg.login(this.$store, login, this.$store.authAndReg.passwordAuth);
      if (QRCode) {
        await actions.authAndReg.QRLogin(this.$store, QRCode);
        await this.$router.replace('/qr-success');
      }
      cookieStore.set('welcomeScreenConfirmed', '0');
      this.$store.common.showProfileConfirmationOverlay = false;
      await this.redirectAfterLogin();
    } catch (err) {
      if (err.code === HTTP_CODES.FORBIDDEN) {
        this.handleError(err);
      } else {
        this.isLoading = true;
        this.changeStep(this.steps.start);
        this.handleError(err);
      }
    }
  }

  async redirectAfterLogin() {
    const backUrl = sessionStore.get('backUrlAfterLogin');
    if (backUrl) {
      await this.$router.push(backUrl);
      sessionStore.remove('backUrlAfterLogin');
    } else {
      await this.goToMainPageWithRouter();
    }

    this.gaEventAuth({ action: 'Клик по кнопке "Войти"' });
  }

  async authWidthPromoCode(login: string) {
    await this.verifyAndGetLogins(this.$store.authAndReg.passwordAuth, login);
    actions.registration
      .verify(this.$store, {
        data: {
          type: 'phone',
          contacts: login,
          code: this.$store.authAndReg.passwordAuth,
        },
      })
      .then(async () => {
        await this.promoCodeRegister();
        this.isLoading = false;
      })
      .catch((err) => {
        this.isLoading = false;
        if (err.code === HTTP_CODES.NOT_FOUND) {
          this.changeStep(AUTH_STEPS.outdated);
        } else if (err.code === HTTP_CODES.BAD_REQUEST) {
          this.showErrorPage(err);
        } else {
          this.errorMessages.push(err.message);
          throw err;
        }
      });
  }

  prepareFinishRegistration() {
    const queryParameters = this.$route.query;
    const lsUtmParameters = localStore.get(LOCAL_STORAGE_NAMES.utmParameters);
    const appVersionParams = getAppVersionParams();

    localStore.remove(LOCAL_STORAGE_NAMES.utmParameters);

    const utmParameters = lsUtmParameters
      ? lsUtmParameters
      : {
          utmSource: queryParameters.utm_source || '',
          utmMedium: queryParameters.utm_medium || '',
          utmCampaign: queryParameters.utm_campaign || '',
          utmContent: queryParameters.utm_content || '',
          utmTerm: queryParameters.utm_term || '',
        };

    return {
      'contacts-session-token': this.$store.verificationResult.sessionToken,
      'phone-info-token': this.$store.authAndReg.phoneInfoToken,
      utm_source: utmParameters.utmSource,
      utm_medium: `${appVersionParams.app.id}.${appVersionParams.app.buildType}`, // https://lifestream.atlassian.net/browse/SEQ-1320,
      utm_campaign: utmParameters.utmCampaign,
      utm_content: utmParameters.utmContent,
      utm_term: utmParameters.utmTerm,
    };
  }

  onClickOnForgotPassword() {
    this.gaEventAuth({
      action: 'Клик "Забыли пароль"',
    });

    if (this.isPhoneMode && this.phone) {
      this.phone = cleanupPhone(this.phone);
      this.$store.authAndReg.passwordAuth = '';
      this.captchaValue = '';
    }
  }
}
