<template>
  <div ref="authSlider" class="auth-slider">
    <template v-for="slide in slides">
      <div
        v-if="activeSlide === slide.id"
        :key="slide.image"
        class="slide"
        :class="[`slide${slide.id}`]"
      >
        <div class="slide-cloud">
          <ImageWithHash class="cloud-img" :src="`/public/images/auth/${slide.cloudImg}`" alt="" />
          <div class="cloud-text" v-html="slide.text" />
        </div>
        <ImageWithHash
          class="slide-img"
          :src="`/public/images/auth/${slide.image}-${$store.theme}.svg`"
          alt=""
        />
      </div>
    </template>
  </div>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { SequoiaComponent } from 'src/mixins';
import { Ref } from 'vue-property-decorator';
import ImageWithHash from 'src/components/ui/ImageWithHash.vue';

@Component({
  components: { ImageWithHash },
})
export default class AuthSlider extends SequoiaComponent {
  activeSlide = 1;
  slides = [
    {
      id: 1,
      cloudImg: 'cloud1.svg',
      text: '',
      image: 'slide1',
    },
    {
      id: 2,
      cloudImg: 'cloud1.svg',
      text: this.getTranslation('auth_promo_code_slide2'),
      image: 'slide2',
    },
    {
      id: 3,
      cloudImg: 'cloud2.svg',
      text: this.getTranslation('auth_promo_code_slide3'),
      image: 'slide3',
    },
    {
      id: 4,
      cloudImg: 'cloud2.svg',
      text: this.getTranslation('auth_promo_code_slide4'),
      image: 'slide4',
    },
  ];
  intervalSlider: NodeJS.Timeout | null = null;

  @Ref('authSlider')
  refAuthSlider!: HTMLDivElement;

  get trialDaysTranslation() {
    return `${this.getTranslation(
      `free_days${this.$route.query.trial ? `_${this.$route.query.trial}` : ''}`
    )}`;
  }

  mounted() {
    this.intervalSlider = setInterval(() => {
      this.activeSlide = this.activeSlide >= this.slides.length ? 1 : (this.activeSlide += 1);
    }, 7000);

    this.slides[0].text = this.trialDaysTranslation;

    window.addEventListener('scroll', () => {
      this.refAuthSlider?.setAttribute('style', `transform: translateY(${window.scrollY * 0.3}px)`);
    });
  }

  beforeDestroy() {
    if (this.intervalSlider) {
      clearInterval(this.intervalSlider);
    }
    window.removeEventListener('scroll', () => {
      return null;
    });
  }
}
</script>
