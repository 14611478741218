import Global from 'src/mixins/Global';
import Component from 'vue-class-component';
import * as api from 'src/api';
import { MEGAFON_IS_TECHNICAL_PARAM, PHONE_INFO_STATUSES } from 'src/constants';
import { wlDetector } from 'src/utils';
import { cleanupPhone } from 'src/utils/phone';

@Component
export default class PhoneCallbacks extends Global {
  async onCompletePhoneCallback(phone: string) {
    if (wlDetector().isMegacom) {
      return;
    }

    try {
      if (wlDetector().isSmotreshka) {
        const { verificationSettings, registrationSettings, phoneInfoToken } =
          await api.auth.getPhoneInfoV2({
            params: {
              phone: cleanupPhone(phone, this.$store.authAndReg.usePlusInPhoneMethods.phoneInfo),
            },
          });

        this.$store.authAndReg.phoneTechnicalSim = verificationSettings.methods.includes(
          MEGAFON_IS_TECHNICAL_PARAM
        );
        this.$store.authAndReg.recommendProvider =
          registrationSettings.recommendProvider.externalProviderId;
        this.$store.authAndReg.phoneInfoToken = phoneInfoToken;
        this.$store.authAndReg.registrationSettings = registrationSettings.methods;
      } else {
        const { supportInfo } = await api.auth.getPhoneInfoV1({
          data: {
            phone: cleanupPhone(phone, this.$store.authAndReg.usePlusInPhoneMethods.phoneInfo),
          },
        });

        this.$store.authAndReg.phoneUnsupported =
          supportInfo.status === PHONE_INFO_STATUSES.UNSUPPORTED;
        this.$store.authAndReg.phoneUnsupportedMessage =
          supportInfo.reason || 'phone_unsupported_message';
      }
    } catch (e) {
      this.$store.authAndReg.phoneUnsupportedMessage = '';
      this.$store.authAndReg.phoneUnsupported = false;
    }
  }

  onIncompletePhoneCallback() {
    this.$store.authAndReg.phoneUnsupportedMessage = '';
    this.$store.authAndReg.phoneUnsupported = false;
  }
}
