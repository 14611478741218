<template>
  <div class="step-promocode">
    <h4 class="h3 mb-24" v-html="title" />

    <BadgeSequoia
      v-if="code"
      size="large"
      icon-size="24"
      typography="h6"
      :text="code"
      :icon="IconGift"
      type="accent"
    />

    <div class="body1 mb-24" v-html="description" />

    <form novalidate autocomplete="off" @submit.prevent="sendPromoCode">
      <InputText
        v-if="!code"
        ref="promoCode"
        v-model="$store.authAndReg.promoCode"
        input-category="floating"
        size="medium"
        required
        class="mb-16"
        :placeholder="promoCodeLabel"
        :label="promoCodeLabel"
      />

      <ButtonDefault
        class="mb-24"
        size="medium"
        data-cy="submit"
        :full-width="true"
        :is-loading="isLoading"
        :disabled="isSubmitPromoCodeButtonDisabled"
      >
        <span v-html="buttonText" />
      </ButtonDefault>
    </form>

    <div class="description body1 mb-16" v-html="getTranslation('auth_promo_code_terms')" />
    <div class="description body1" v-html="getTranslation('auth_promo_code_text')" />
  </div>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import Auth from 'src/components/auth/Auth';
import * as api from 'src/api';
import { Ref, Vue } from 'vue-property-decorator';
import { Prop } from 'vue-property-decorator';
import logger from 'src/utils/logger';

const log = logger('app');

@Component
export default class StepPromoCode extends Auth {
  @Prop({ default: '' })
  code?: string;

  @Prop({ default: '' })
  title!: string;

  @Prop({ default: '' })
  description!: string;

  @Prop({ default: '' })
  buttonText!: string;

  @Ref('promoCode')
  refPromoCode?: Vue;

  get promoCodeLabel() {
    return this.getTranslation('auth_promo_code_title');
  }

  get isSubmitPromoCodeButtonDisabled() {
    return !this.$store.authAndReg.promoCode.length && !this.code;
  }

  async sendPromoCode() {
    this.isLoading = true;

    try {
      const { isValueFound, promocodeInfo } = await api.auth.promoCodeCheck({
        params: { value: this.$store.authAndReg.promoCode },
      });

      this.$store.authAndReg.promoCodeResultText = promocodeInfo?.infoMessage || '';

      if (isValueFound && promocodeInfo?.isApplicable) {
        this.$store.authAndReg.promoCodeValid = true;
        this.$store.authAndReg.promoCodeId = promocodeInfo?.promocodeId;
        this.$store.authAndReg.canonicalValue = promocodeInfo?.canonicalValue;
        if (this.$route.name === 'code') {
          await this.$router.push(this.isSmotreshka || this.isN3 ? '/reg' : '/login');
        } else {
          this.changeStep(this.isMts ? this.steps.authMts : this.steps.start);
        }
      } else if (this.$route.name === 'code') {
        this.$emit('changeStep');
      } else {
        this.changeStep(this.steps.promoCodeNotFound);
      }
    } catch (e) {
      log.error(e);
      this.$store.authAndReg.promoCodeResultText = '';
      if (this.$route.name === 'code') {
        this.$emit('changeStep');
      } else {
        this.changeStep(this.steps.error);
      }
    } finally {
      this.isLoading = false;
    }
  }

  mounted() {
    this.$store.authAndReg.promoCode = '';
    if (this.code) {
      this.$store.authAndReg.promoCode = this.code;
    }
    if (this.$route.name !== 'code') {
      (this.refPromoCode?.$refs.input as HTMLInputElement | undefined)?.focus();
    }
  }
}
</script>
