<template>
  <ButtonDefault
    view="tertiary"
    size="medium"
    text-align="left"
    class="mb-8"
    :with-icon="true"
    :full-width="fullWidth"
    :width-caption="true"
    :is-external="isExternal"
    :href="href"
    @click="$emit('click')"
  >
    <span class="with-caption">
      <span v-html="title" />
      <span v-if="caption" class="caption2" v-html="caption" />
    </span>
    <LoaderSpinner v-if="isLoading" />
    <IconSVG v-if="!isLoading && navigationIcon" class="navigation-icon" :svg="navigationIcon" />
  </ButtonDefault>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { SequoiaComponent } from 'src/mixins';
import ButtonDefault from 'src/components/ui/buttons/ButtonDefault.vue';
import IconSVG from 'src/components/IconSVG.vue';
import LoaderSpinner from 'src/components/ui/loader/LoaderSpinner.vue';

@Component({
  components: { IconSVG, ButtonDefault, LoaderSpinner },
})
export default class ButtonTableCell extends SequoiaComponent {
  @Prop({ default: '' })
  caption!: string;

  @Prop({ default: '' })
  title!: string;

  @Prop({ default: '' })
  navigationIcon!: Record<string, unknown>;

  @Prop({ default: false })
  isExternal!: boolean;

  @Prop({ default: false })
  fullWidth!: boolean;

  @Prop()
  href?: string;

  @Prop()
  isLoading?: boolean;
}
</script>

<style lang="scss" scoped>
.with-caption {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.navigation-icon {
  opacity: 0.6;
}

.button {
  &.default {
    &.with-icon {
      padding-right: 16px;
    }

    .content .icon {
      margin-right: 0;
    }

    .content .loader-spinner {
      position: absolute;
      right: 0;
      width: 28px;
      height: 100%;
    }
  }
}

.light {
  &::v-deep {
    .caption2 {
      color: var(--c-light-font-secondary);
    }
  }
}

.dark {
  &::v-deep {
    .caption2 {
      color: var(--c-dark-font-secondary);
    }
  }
}
</style>
