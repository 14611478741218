<template>
  <div class="step-other" :class="{ 'result-step': resultStep }">
    <div v-if="beforeTitle" class="description mb-24" v-html="beforeTitle" />

    <div v-if="iconName" class="result-icon">
      <ImageWithHash
        v-if="widthMarker"
        class="failed-marker"
        :src="`/public/images/auth/failed-marker.svg`"
        alt=""
      />
      <ImageWithHash :src="`/public/images/auth/${iconName}.svg`" alt="" />
    </div>

    <h4 v-if="title" class="h3 mb-16" v-html="title" />

    <div class="description mb-32">
      <slot name="description" />
      <span
        v-if="linkInDescription"
        class="link nav-underlined"
        @click="onClickDescriptionLink"
        v-html="linkInDescription"
      />
    </div>

    <div v-if="error" class="mb-24" v-text="errorText" />

    <ButtonDefault
      v-if="buttonText"
      :is-loading="isLoading"
      :disabled="isLoading"
      size="medium"
      :full-width="true"
      :view="buttonType ? buttonType : 'primary'"
      @click="$emit('clickButton')"
    >
      <span v-html="buttonText" />
    </ButtonDefault>

    <ButtonDefault
      v-if="buttonText2"
      view="tertiary"
      class="mt-16"
      size="medium"
      :full-width="true"
      @click="$emit('clickButton2')"
    >
      <span v-html="buttonText2" />
    </ButtonDefault>

    <ButtonTableCell
      v-if="login"
      :title="login"
      :fullWidth="true"
      :navigation-icon="IconAngleRight"
      :is-loading="isLoading"
      @click="$emit('clickLogin')"
    />

    <div v-if="$slots.afterButton" class="body1 color-secondary mt-32">
      <slot name="afterButton" />
    </div>

    <LoaderSpinner v-if="descriptionActionsLoading" />
  </div>
</template>

<script lang="ts">
import Component from 'vue-class-component';
import Auth from 'src/components/auth/Auth';
import { Prop } from 'vue-property-decorator';
import ImageWithHash from 'src/components/ui/ImageWithHash.vue';
import ButtonTableCell from 'src/components/ui/buttons/ButtonTableCell.vue';
import IconAngleRight from 'src/svg/angle-right.svg';

@Component({
  components: { ImageWithHash, ButtonTableCell },
})
export default class StepOther extends Auth {
  IconAngleRight = IconAngleRight;

  @Prop({ default: '' })
  title!: string;

  @Prop({ default: '' })
  beforeTitle!: string;

  @Prop({ default: '' })
  linkInDescription!: string;

  @Prop({ default: '' })
  buttonText!: string;

  @Prop({ default: '' })
  buttonText2!: string;

  @Prop({ default: false })
  showButtonGoToHomePage!: boolean;

  @Prop({ default: false })
  error!: boolean;

  @Prop({ default: '' })
  iconName!: string;

  @Prop({ default: false })
  widthMarker!: boolean;

  @Prop({ default: '' })
  buttonType!: 'primary' | 'secondary' | 'tertiary' | 'custom' | 'danger';

  @Prop({ default: false })
  resultStep!: boolean;

  @Prop({ default: '' })
  login!: string;

  descriptionActionsLoading = false;

  onClickDescriptionLink() {
    this.descriptionActionsLoading = true;
    this.$emit('linkInDescription');
  }

  updated() {
    this.descriptionActionsLoading = false;
  }
}
</script>

<style scoped lang="scss">
.step-other {
  .description {
    word-break: break-word;
  }

  &.result-step {
    text-align: center;
  }

  .result-icon {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 160px;
    height: 160px;
    margin: 16px auto 32px;
    background-color: var(--c-light-surface-150);
    border-radius: 50%;
  }

  .failed-marker {
    position: absolute;
    top: 16%;
    right: 10%;
  }
}
</style>
